<template>
  <div class="form-group">
    <textarea
      class="form-control form-control-sm mb-2"
      placeholder="ที่อยู่"
      rows="2"
      v-model="address.address"
      :disabled="disabled"
      @input="handleAddressChange"
    />

    <div class="manualInput w-100">
      <div class="d-flex flex-column">
        <div class="d-flex mb-2">
          <input
            id="sa-amphoe"
            type="text"
            class="form-control form-control-sm"
            v-model="address.subDistrict"
            placeholder="ตำบล"
            autocomplete="false"
            @input="handleAddressChange"
            :disabled="disabled"
          />
          <div class="m-2"></div>
          <input
            id="sa-district"
            type="text"
            class="form-control form-control-sm"
            v-model="address.district"
            placeholder="อำเภอ"
            autocomplete="false"
            @input="handleAddressChange"
            :disabled="disabled"
          />
        </div>
        <div class="d-flex justify-content-between">
          <input
            id="sa-province"
            type="text"
            class="form-control form-control-sm mr-2 flex-grow-1"
            v-model="address.province"
            placeholder="จังหวัด"
            autocomplete="false"
            @input="handleAddressChange"
            :disabled="disabled"
          />
          <div class="m-2"></div>

          <input
            id="sa-zipcode"
            type="text"
            class="form-control form-control-sm"
            v-model="address.zipcode"
            placeholder="รหัส ปณ."
            autocomplete="false"
            @input="handleAddressChange"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "SmartAddress",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: null,
    },
  },

  data() {
    return {
      address: {
        address: null,
        district: null,
        subDistrict: null,
        province: null,
        zipcode: null,
      },
    };
  },

  created() {
    if (this.value) {
      if (this.value.address) this.address.address = this.value.address;
      if (this.value.district) this.address.district = this.value.district;
      if (this.value.subDistrict) this.address.subDistrict = this.value.subDistrict;
      if (this.value.province) this.address.province = this.value.province;
      if (this.value.zipcode) this.address.zipcode = this.value.zipcode;
    }
  },

  mounted() {
    this.applyThaiAddParse();
  },

  methods: {
    handleAddressChange() {
      this.address.raw = `${this.address.address || ""}\n${
        this.address.district || ""
      }\n${this.address.subDistrict || ""}\n${this.address.province || ""}\n${
        this.address.zipcode || ""
      }`;
      this.emitChanges();
    },

    applyThaiAddParse() {
      setTimeout(() => {
        $.Thailand({
          database:
            "https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/database/db.json",
          $district: $(`#sa-district`),
          $amphoe: $(`sa-amphoe`),
          $province: $(`#sa-province`),
          $zipcode: $(`#sa-zipcode`),
          onDataFill: (data) => {
            this.address.district = data.amphoe;
            this.address.subDistrict = data.district;
            this.address.province = data.province;
            this.address.zipcode = data.zipcode;
            this.address.raw = `${this.address.address}\n${data.district}\n${data.amphoe}\n${data.province}\n${data.zipcode}`;
            this.emitChanges();
          },
        });
      }, 500);
    },

    emitChanges() {
      this.$emit("addressChange", this.address);
    },
  },
};
</script>

<style lang="scss">
</style>