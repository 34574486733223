import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./styles/main.scss";
import Toaster from "@meforma/vue-toaster";

window.$ = window.jQuery = require('jquery');

createApp(App).use(store).use(router).use(Toaster, {
    position: "bottom",
}).mount('#app')
