<template>
  <div class="modal fade" :id="id" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    id: {
      type: String
    },
    custom_dialog_class:{
      type: String,
      default: ""
    },
    backdrop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots['header'];
    }
  }
};
</script>

<style>
</style>