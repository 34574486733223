import { http } from "@/helpers/http";

const baseUrl = "https://3o7l42rooa.execute-api.ap-southeast-1.amazonaws.com/dev/v1";
// const baseUrl = "http://localhost:3000/dev/v1";
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {

        checkOrder(obj, payload) {
            http.get(baseUrl + "/public-order/" + payload.id).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(() => {
                if (payload.callback) payload.callback(false);
            })
        },

        fetchOrderDetails(obj, payload) {
            http.get(baseUrl + "/public-order/" + payload.id).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(() => {
                if (payload.callback) payload.callback(false);
            })
        },

        updateOrderDetails(obj, payload) {
            http.put(baseUrl + "/public-order/", payload.data).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(() => {
                if (payload.callback) payload.callback(false);
            })
        },

        processSlip(obj, payload) {
            http.post(baseUrl + "/orders/" + payload.id + '/process-slip', payload.data).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(() => {
                if (payload.callback) payload.callback(false);
            })
        }
    }
}