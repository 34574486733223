const generatePayload = require("promptpay-qr");
const qrcode = require('qrcode');

export const generateQr = async (identifier, amount) => {
    const payload = generatePayload(identifier, { amount });
    const options = {
      type: "svg",
      color: { dark: "#003b6a", light: "#f7f8f7" },
    };
    return await new Promise((resolve, reject) => {
      qrcode.toString(payload, options, (err, svg) => {
        if (err) return reject(err);
        resolve(svg);
      });
    });
}