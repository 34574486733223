<template>
  <div class="reciept-page-content">
    <div
      v-if="checkoutSettings && checkoutSettings.preOrderMessageFormat"
      class="message-wrapper"
    >
      <div v-html="checkoutSettings.preOrderMessageFormat"></div>
    </div>

    <div v-if="orderDetails">
      <div class="reciept">
        <div class="reciept-bg-top"></div>
        <div class="payment-reciept-wrapper">
          <!-- Order Details -->
          <div class="d-flex justify-content-between">
            <div class="order-no">
              <p class="--text-vsm mb-2">
                #{{ orderDetails.details.orderCode }}
              </p>
              <p class="mb-0" v-if="orderDetails.trackingNo">
                <a
                  :href="
                    'http://track.proship.co.th?barcode=' +
                    orderDetails.trackingNo
                  "
                  class="text-muted"
                  :style="{ textDecoration: 'none' }"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ orderDetails.trackingNo }}
                </a>
              </p>

              <p class="--text-vsm">
                สถานะ: {{ getOrderStatus(orderDetails.status).title }}
              </p>
            </div>

            <div>
              <p :style="{ textAlign: 'right' }" class="--text-vsm">
                {{ createdAtDateTime }}
              </p>
              <p :style="{ textAlign: 'right' }" class="--text-vsm">
                ผู้ส่ง<span class="text-capitalize"
                  >: {{ orderDetails.shippingMethod }}</span
                >
              </p>
              <p :style="{ textAlign: 'right' }" class="--text-vsm">
                การชำระเงิน:
                <span>{{ orderDetails.details.paymentMode || "N/A" }} </span>
              </p>
            </div>
          </div>
          <div class="horizontal-dotted-line"></div>

          <!-- Shipping Details -->
          <div
            class="shipping-contact-details"
            v-if="orderDetails.details.customer"
          >
            <!-- <p class="lead mb-4">Shipment Details</p> -->
            <div class="row mb-2">
              <div class="col">
                <label class="mb-0" for="name">ชื่อ</label>
                <input
                  type="text"
                  id="name"
                  v-model="orderDetails.details.customer.name"
                  placeholder="ชื่อ"
                  class="form-control form-control-sm"
                  :disabled="isVerifiedInfo"
                />
              </div>
              <div class="col">
                <label class="mb-0" for="phone">เบอร์โทร</label>
                <input
                  v-if="isVerifiedInfo"
                  type="text"
                  id="phone"
                  :value="hiddenPhoneNo"
                  placeholder="เบอร์โทร"
                  class="form-control form-control-sm"
                  :disabled="isVerifiedInfo"
                />
                <input
                  v-else
                  type="text"
                  id="phone"
                  v-model="orderDetails.details.customer.phoneNo"
                  placeholder="เบอร์โทร"
                  class="form-control form-control-sm"
                  v-on:keypress="limitPhoneKeypress"
                />
                <span
                  class="--text-vvsm"
                  :style="{
                    display: 'block',
                    marginTop: '-0.15rem',
                    color: '#f67474',
                  }"
                  v-if="phoneNoError"
                  >{{ phoneNoError }}</span
                >
              </div>
            </div>

            <SmartAddress
              :value="orderDetails.details.customer.address"
              @addressChange="handleAddressChange"
              :disabled="isVerifiedInfo"
            />
            <div class="horizontal-dotted-line"></div>
          </div>

          <!-- Products -->
          <div
            v-if="
              orderDetails.details.products &&
              Object.keys(orderDetails.details.products).length
            "
          >
            <p class="lead mb-4">Products</p>
            <div class="products" @scroll="onProductsScroll">
              <transition name="fade" v-if="totalQty > 5">
                <div
                  class="scroll-message text-center text-white"
                  v-if="!isScrolled"
                >
                  SCROLL
                  <i class="scroll-down-icon fal fa-angle-down"></i>
                </div>
              </transition>
              <template
                v-for="(product, proshipCode) in orderDetails.details.products"
                :key="proshipCode"
              >
                <div
                  class="
                    product
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <div class="flex-grow-1">
                    <span :style="{ fontWeight: 'bold' }" class="--text-sm">{{
                      product.sku
                    }}</span>
                    x {{ product.qty }}
                  </div>

                  <div class="product-amount">
                    <span>฿ {{ product.price * product.qty }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div>
              <table class="table table-sm">
                <tr class="text-muted --text-sm">
                  <td>Subtotal</td>
                  <td class="text-right">฿{{ totalPrice }}</td>
                </tr>
                <tr class="text-muted --text-sm">
                  <td>Shipping Cost</td>
                  <td class="text-right">
                    ฿{{ orderDetails.details.shippingCostCharged }}
                  </td>
                </tr>
                <tr class="text-muted --text-sm">
                  <td>Discount</td>
                  <td class="text-right">
                    ฿{{ orderDetails.details.discountAmount }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Total
                    <!-- <span class="--text-vvsm p-0">
                      (Subtotal + Shipping Cost - Discount)
                    </span> -->
                  </td>
                  <td class="text-right">
                    ฿{{
                      totalPrice +
                      orderDetails.details.shippingCostCharged -
                      orderDetails.details.discountAmount
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Payment -->
          <div>
            <!-- Payment option selector -->
            <div class="d-flex">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="paymentType"
                  value="promptPay"
                  id="promptPay"
                  v-model="paymentMode"
                  :disabled="paymentSaved"
                />
                <label class="form-check-label" for="promptPay">
                  พร้อมเพย์
                </label>
              </div>
              <div class="mx-4"></div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="paymentType"
                  value="bank"
                  id="bank"
                  v-model="paymentMode"
                  :disabled="paymentSaved"
                />
                <label class="form-check-label" for="bank"> ธนาคาร </label>
              </div>
              <div class="mx-4"></div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="paymentType"
                  value="cod"
                  id="cod"
                  v-model="paymentMode"
                  :disabled="paymentSaved"
                />
                <label class="form-check-label" for="cod"> COD </label>
              </div>
            </div>
            <div class="horizontal-dotted-line mt-2"></div>

            <div v-if="paymentMode == 'bank'">
              <p class="mb-2 --text-sm">
                <span class="font-weight-bold">ธนาคาร:</span>
                {{ bankInfo(checkoutSettings.bank.name).nice_name }}
                <br />
                <span class="font-weight-bold">ชื่อบัญชี:</span>
                {{ checkoutSettings.bank.accName }}
                <br />
                <span class="font-weight-bold">เลขบัญชี:</span>
                {{ checkoutSettings.bank.accNo }}
                <br />
                <span class="font-weight-bold">ยอดเงิน:</span> ฿
                {{ orderDetails.details.totalSalesPrice || 0 }}
              </p>

              <div
                v-if="
                  orderDetails.details.bank && orderDetails.details.bank.slip
                "
                class="mb-2"
              >
                <a
                  :href="slip"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-for="(slip, index) in orderDetails.details.bank.slip"
                  :key="index"
                >
                  <img
                    :src="slip"
                    :style="{
                      height: '50px',
                      width: 'auto',
                      cursor: 'pointer',
                    }"
                    class="border rounded cursor-pointer"
                  />
                </a>
              </div>

              <input
                type="file"
                @change="(e) => handleFileChange(e, 'bank')"
                class="d-none"
                ref="bankFileUpload"
                :disabled="paymentSaved"
              />
              <button
                type="button"
                class="btn btn-sm w-100 bg-secondary"
                @click="$refs.bankFileUpload.click()"
                :disabled="paymentSaved"
              >
                อัพโหลดภาพสลิป
              </button>
              <div class="horizontal-dotted-line mt-2"></div>
            </div>

            <div v-else-if="paymentMode == 'promptPay'" class="">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                  <p class="--text-sm flex-grow-1">
                    <span class="font-weight-bold">เบอร์พร้อมเพย์:</span>
                    {{ checkoutSettings.promptPay.id }}
                    <br />
                    <span class="font-weight-bold">ชื่อ:</span>
                    {{ checkoutSettings.promptPay.name }}
                    <br />
                    <span class="font-weight-bold">ยอดเงิน:</span> ฿{{
                      orderDetails.details.totalSalesPrice || 0
                    }}
                    <br />

                    <span
                      v-if="
                        orderDetails.details.promptPay &&
                        orderDetails.details.promptPay.slip
                      "
                      class="mb-2"
                    >
                      <a
                        :href="slip"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-for="(slip, index) in orderDetails.details.promptPay
                          .slip"
                        :key="index"
                      >
                        <img
                          :src="slip"
                          :style="{
                            height: '50px',
                            width: 'auto',
                            cursor: 'pointer',
                          }"
                          class="border rounded cursor-pointer"
                        />
                      </a>
                    </span>
                  </p>

                  <input
                    type="file"
                    @change="(e) => handleFileChange(e, 'promptPay')"
                    class="d-none"
                    ref="promptPayFileUpload"
                    :disabled="paymentSaved"
                  />
                  <button
                    type="button"
                    class="btn btn-sm w-100 bg-secondary"
                    @click="$refs.promptPayFileUpload.click()"
                    :disabled="paymentSaved"
                  >
                    อัพโหลดภาพสลิป
                  </button>
                </div>
                <div
                  v-if="promptpayQr"
                  v-html="promptpayQr"
                  :style="{ width: '10rem', margin: '0 auto' }"
                ></div>
                <p v-else class="text-muted alert alert-warning">
                  QR ไม่สามารถแสดงได้
                </p>
                <div></div>
              </div>

              <div class="horizontal-dotted-line mt-2"></div>
            </div>

            <!-- bank details -->
          </div>

          <!-- Buttons -->
          <div class="row" v-if="!isVerifiedInfo">
            <div class="col">
              <button
                type="button"
                class="btn btn-primary w-100 mt-2"
                @click="updateOrderDetails()"
                :disabled="updateLoading"
              >
                <div v-if="updateLoading">
                  <img
                    src="@/assets/loading-white.svg"
                    :style="{ width: '16px' }"
                  />
                  บันทึก
                </div>

                <span v-else> บันทึก </span>
              </button>
            </div>
          </div>
        </div>
        <div class="reciept-bg-down"></div>
      </div>

      <Modal
        id="upload-payment-slip-modal"
        ref="uploadSlipModal"
        :backdrop="true"
      >
        <template v-slot:header>
          <h5 class="modal-title">Payment</h5>
        </template>
        <template v-slot:body>
          <p>Select payment option</p>

          <div class="mt-4 mb-2 text-center">
            <button
              type="button"
              class="btn btn-primary w-100"
              data-dismiss="modal"
            >
              Confirm payment
            </button>
          </div>

          <!-- <DropZone @uploaded="handleSlipUpload" /> -->
        </template>
      </Modal>
    </div>

    <div v-else-if="!orderDetails && !loading" class="mt-5">
      <div class="alert alert-warning">Invalid request</div>
    </div>

    <div v-if="loading">
      <Spinner size="md" />
    </div>
  </div>
</template>

<script>
import Modal from "@/components/core/Modal";
import Spinner from "@/components/core/Spinner";
import SmartAddress from "@/components/core/SmartAddress";
import { FILTERS } from "@/helpers/filters";
import { getOrderStatus, bankInfo } from "@/helpers/core";
import axios from "axios";
import { generateQr } from "@/helpers/promptpay";

export default {
  name: "OrderDetails",
  components: {
    Modal,
    Spinner,
    SmartAddress,
  },

  data() {
    return {
      url: null,
      orderId: null, // "order-6245c5e0-3548-11ec-8ca8-11e8fab39c681635134554430"
      orderDetails: null,
      isScrolled: false,
      paymentMethods: null,
      selectedPaymentMethod: {},
      loading: false,
      updateLoading: false,

      paymentMode: "N/A", // bank, prompt-pay, cod
      hasPaymentSlip: false,
      checkoutSettings: null,
      promptpayQr: null,
    };
  },

  created() {
    this.url = window.location;

    let id = this.$route.query.r || this.$route.query.res;
    if (id) {
      this.orderId = "order-" + id;
      console.log(this.orderId);
      this.fetchOrderDetails();
    }
  },

  methods: {
    handleAddressChange(address) {
      this.orderDetails.details.customer.address = address;
    },

    getOrderStatus(status) {
      return getOrderStatus(status);
    },

    fetchOrderDetails() {
      this.loading = true;
      this.$store.dispatch("order/fetchOrderDetails", {
        id: this.orderId,
        callback: async (status, data) => {
          if (data) {
            this.checkoutSettings = data.checkoutSettings;
            data = data.data;
          }
          if (status) {
            //  Bank
            if (!data.details.bank) {
              data.details.bank = {
                name: null,
                date: null,
                time: null,
                amount: null,
                slips: null,
              };
              this.hasPaymentSlip = false;
            } else {
              if (
                data.details.bank &&
                data.details.bank.slip &&
                data.details.bank.slip.length
              )
                this.hasPaymentSlip = true;
            }

            // Customer address
            if (!data.details.customer.address)
              data.details.customer.address = {
                raw: null,
                address: null,
                district: null,
                subDistrict: null,
                province: null,
                zipcode: null,
              };

            // cost
            if (!data.details.shippingCostCharged)
              data.details.shippingCostCharged = 0;
            if (!data.details.discountAmount) data.details.discountAmount = 0;

            this.orderDetails = data;
            this.paymentMode = this.orderDetails.details.paymentMode || "N/A";
          }
          if (
            this.checkoutSettings &&
            this.checkoutSettings.promptPay.id &&
            data.details.totalSalesPrice
          )
            this.promptpayQr = await generateQr(
              this.checkoutSettings.promptPay.id,
              data.details.totalSalesPrice
            );
          this.loading = false;
        },
      });
    },

    updateOrderDetails() {
      // validate form details
      let valid = this.validateForm();
      if (valid != "valid") {
        this.$toast.show(valid);
        return;
      }

      this.updateLoading = true;
      this.$store.dispatch("order/updateOrderDetails", {
        data: {
          id: this.orderDetails.id,
          bank: this.orderDetails.details.bank,
          promptPay: this.orderDetails.details.promptPay,
          paymentMode: this.paymentMode,
          customer: {
            address: this.orderDetails.details.customer.address,
            name: this.orderDetails.details.customer.name,
            phoneNo: this.orderDetails.details.customer.phoneNo,
          },
        },
        callback: (status) => {
          if (status) {
            this.$toast.show(`Updated Successfully`);
            this.fetchOrderDetails();
          }
          this.updateLoading = false;
        },
      });
    },

    async handleFileChange(e, type) {
      let file = e.target.files[0];
      let fd = new FormData();
      fd.append("file", file);
      let resp = await axios.post(
        "https://8enalpa7e5.execute-api.ap-southeast-1.amazonaws.com/dev/v1/media",
        fd,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      if (type == "bank") {
        this.orderDetails.details.bank.slip = resp.data.urls;
        // also fill up other bank details
        if (this.checkoutSettings.bank) {
          this.orderDetails.details.bank.name = this.checkoutSettings.bank.name;
          this.orderDetails.details.bank.amount =
            this.orderDetails.details.totalSalesPrice;
        }
      } else if (type == "promptPay") {
        // TODO
        this.orderDetails.details.promptPay = { slip: resp.data.urls };
      }
    },

    backGround(bank) {
      return "background-color:" + bankInfo(bank.bank).color + ";";
    },

    onProductsScroll() {
      return (this.isScrolled = true);
    },

    selectPaymentMethod(paymentMethod) {
      return (this.selectedPaymentMethod = paymentMethod);
    },

    paymentMethodClass(paymentMethod) {
      return paymentMethod.name == this.selectedPaymentMethod.name
        ? "--active"
        : "";
    },

    bankInfo(name) {
      return bankInfo(name);
    },

    validateForm() {
      let address = this.orderDetails.details.customer.address;
      if (address) {
        if (!address.address) return "Address is required";
        else if (!address.district) return "District is required";
        else if (!address.subDistrict) return "Sub District is required";
        else if (!address.province) return "Province is required";
        else if (!address.zipcode) return "Zipcode is required";
      }
      if (!this.orderDetails.details.customer.phoneNo)
        return "Phone No. is required";
      else if (this.phoneNoError) return "Phone no." + this.phoneNoError;
      return "valid";
    },

    limitPhoneKeypress(event) {
      let phone = this.orderDetails.details.customer.phoneNo;
      if (phone && phone.length >= 10) {
        event.preventDefault();
      }
    },
  },

  computed: {
    totalPrice() {
      if (this.orderDetails && this.orderDetails.details.products) {
        let total = 0;
        Object.values(this.orderDetails.details.products).forEach(
          (product) => (total += product.qty * product.price)
        );
        return total;
      }
      return 0;
    },

    totalQty() {
      if (this.orderDetails.details.products)
        return Object.values(this.orderDetails.details.products).length;
      return 0;
    },

    createdAtDateTime() {
      return FILTERS.formatDateTime(this.orderDetails.createdAt);
    },

    hiddenPhoneNo() {
      let phoneNo = this.orderDetails.details.customer.phoneNo;
      if (phoneNo && phoneNo.length > 1) {
        let prefix = phoneNo[0];
        let suffix = phoneNo.charAt(phoneNo.length - 1);
        let body = "";
        for (let i = 0; i < phoneNo.length - 2; i++) body += "*";
        return prefix + body + suffix;
      }
      return "N/A";
    },

    isVerifiedInfo() {
      return this.orderDetails.status != 100 && this.orderDetails.status != 101;
    },

    paymentSaved() {
      return (
        this.orderDetails.status == 101 ||
        this.orderDetails.status == 102 ||
        this.orderDetails.status == 1
      );
    },

    phoneNoError() {
      let phone = this.orderDetails.details.customer.phoneNo + "";
      if (phone && phone.length) {
        if (phone[0] != "0") return "Must begin with 0";
        else if (phone.length != 10) return "Must have 10 digits";
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.reciept-page-content {
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.payment-method {
  padding: 22px;
  margin-right: 20px;
  border-radius: 12px;
  cursor: pointer;

  &.--active {
    background-color: map-get($text-colors, 6);
  }

  img {
    width: 60px;
    height: 60px;
  }
}

.thanks-img-wrapper {
  width: 70%;
  margin: 0 auto 40px auto;
}

.payment-status-tag {
  width: 70%;
  margin: 0 auto;
  margin-top: 16px;
  padding: 2px 0;
  border-radius: 20px;
  &.--pending {
    color: $brand-yellow-dark;

    background-color: $brand-yellow-dark-25;
  }
  &.--done {
    color: $brand-green-mid;
    background-color: $brand-green-mid-25;
  }
}

.payment-details {
  @media only screen and (min-width: 1162px) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
  }
  position: relative;
  margin: 30px 0;
}

.payment-details-title {
  font-size: 14px;
  color: map-get($text-colors, 5);
}
.submit-slip-button {
  margin-top: 17px;
  @include for-laptops-only {
    margin-top: 10px;
  }
}

.payment-detail-image-wrapper {
  margin-right: 12px;
}

.payment-detail-text {
  width: 267px;
  color: map-get($text-colors, 4);
  font-weight: $font-weight-medium;
}
.payment-detail-image {
  width: 47px;
  height: 47px;
}

.payment-details-title {
  margin-bottom: 12px;
}

.payment-methods-title {
  color: map-get($text-colors, 4);
  font-weight: $font-weight-semi-bold;
}

.payment-methods {
  margin-top: 17px;
  margin-bottom: 37px;
  @include for-laptops-only {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.payment-details {
  background-color: white;
  padding: 40px;
  @include for-laptops-only {
    padding: 12px;
  }
  border-radius: 20px;
}

.payment-reciept-wrapper {
  width: 100%;
  height: 100%;
  padding: 38px;
  background-color: white;
  overflow: auto;

  @include for-laptops-only {
    padding: 12px;
  }

  @include for-desktops-and-up {
    min-height: 715px;
  }

  @media only screen and (min-width: 465px) {
    width: 460px;
  }
}

.scroll-message {
  background-color: map-get($text-colors, 3);
  font-size: 12px;
  padding: 5px 12px;
  border-radius: 20px;
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 50px;

  .scroll-down-icon {
    margin-left: 6px;
  }
}
.contact-icon {
  margin-right: 8px;
}

.button-group-reciept {
  margin-top: 30px;
  @include for-laptops-only {
    margin-top: 15px;
  }
}

.back-button {
  margin-right: 24px;
}
.shiping-info-title {
  color: map-get($text-colors, 5);
}
.shipping-contact-details {
  font-size: 14px;
  font-weight: $font-weight-medium;
}
// .shipping-info {
//   width: 180px;
// }

.shipping-info-wrapper {
  margin-top: 4px;
}
.horizontal-dotted-line {
  border: 1px dashed #cdcdcd;
  width: 100%;
  margin: 1rem 0;
  @include for-laptops-only {
    margin: 15px 0;
  }
}

.payment-details-meta {
  font-size: 14px;
  color: map-get($text-colors, 5);
  margin-bottom: 30px;
  @include for-laptops-only {
    margin-bottom: 15px;
  }
}

.product-image {
  margin-right: 12px;
  width: 52px;
  height: 52px;
  img {
    border-radius: 5px;
    width: 52px;
    height: 52px;
  }
}

.products {
  max-height: 304px;
  overflow: scroll;
  overflow-x: hidden;
  font-size: 14px;
  position: relative;

  @include for-laptops-only {
    max-height: 150px;
  }
}

.product {
  color: map-get($text-colors, 4);
  margin-bottom: 24px;
  @include for-laptops-only {
    margin-bottom: 8px;
  }
}

.product-amount {
  margin-right: 14px;
}

.product-price {
  width: 100%;
  text-align: right;
  &.--total {
    color: map-get($text-colors, 3);
    font-size: 22px;
    font-weight: $font-weight-medium;
  }
}

html {
  overflow: auto;
}
body {
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 20px;
  right: 20px;
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.product-price-details {
  color: map-get($text-colors, 5);
  font-size: 14px;
  .product-price-detail-title {
    margin-right: 91px;
    width: 72px;
  }
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 3px;
  border-radius: 3x;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: map-get($text-colors, 6);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: map-get($text-colors, 6);
}

.message-wrapper {
  width: 100%;
  overflow: auto;

  @include for-laptops-only {
    padding: 12px;
  }

  @media only screen and (min-width: 465px) {
    width: 460px;
  }
}
</style>