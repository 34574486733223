export const getOrderStatus = (statusCode) => {
  if (statusCode == 0) statusCode = 6;
  const STATUS = {
    1: {
      title: "Pending",
      variation: "warning",
    },
    2: {
      title: "T Ship",
      variation: "primary",
    },
    3: {
      title: "จัดส่ง",
      variation: "info",
    },
    4: {
      title: "Delivered",
      variation: "success",
    },
    5: {
      title: "Cancelled",
      variation: "danger",
    },
    6: {
      title: "Error",
      variation: "danger",
    },
    8: {
      title: "Return_to_sender",
      variation: "info",
    },

    100: {
      title: "รายการใหม่",
      variation: "warning"
    },

    101: {
      title: "รอยืนยัน",
      variation: "warning",
    },

    102: {
      title: "ชำระแล้ว",
      variation: "primary",
    }
  };
  if (!statusCode) return STATUS;
  return STATUS[statusCode];
};


export const bankInfo = (bankName) => {
  const banks = {
    bbl: { code: "002", color: "#1e4598", nice_name: "Bangkok Bank" },
    bay: {
      code: "025",
      color: "#fec43b",
      nice_name: "Bank of Ayudhya (Krungsri)",
    },
    gsb: {
      code: "030",
      color: "#eb198d",
      nice_name: "Government Savings Bank",
    },
    ghb: {
      code: "033",
      color: "#f57d23",
      nice_name: "Government Housing Bank",
    },
    ibank: {
      code: "066",
      color: "#184615",
      nice_name: "Islamic Bank of Thailand",
    },
    kbank: { code: "004", color: "#138f2d", nice_name: "Kasikornbank" },
    ktb: { code: "006", color: "#1ba5e1", nice_name: "Krungthai Bank" },
    kk: { code: "069", color: "#199cc5", nice_name: "Kiatnakin Bank" },
    lhb: {
      code: "073",
      color: "#6d6e71",
      nice_name: "Land and Houses Bank",
    },
    scb: {
      code: "014",
      color: "#4e2e7f",
      nice_name: "Siam Commercial Bank",
    },
    tbank: {
      code: "065",
      color: "#fc4f1f",
      nice_name: "Thanachart Bank",
    },
    tmb: { code: "011", color: "#1279be", nice_name: "TMB Bank" },
    uob: {
      code: "024",
      color: "#0b3979",
      nice_name: "United Overseas Bank (Thai)",
    },
    cod: {
      code: "000",
      color: "#ffffff",
      nice_name: "Cash On Delivery",
    },
  };
  return banks[bankName];
};

export const isMobile = () => {
  let condition_1 = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  let condition_2 = window.innerWidth < 768;
  return condition_1 || condition_2;
};
