import moment from 'moment-timezone';

export const FILTERS = {
    formatDate: (value) => {
        // return value ? moment(value, "x").format("DD MMM YYYY") : '';
        return value ? moment(value, "x").tz("Asia/Bangkok").format("DD MMM YYYY") : '';
    },

    formatDateTime: (value) => {
        return value ? moment(value, "x").tz("Asia/Bangkok").format("Do MMM YYYY hh:mm a") : '';
        // return value ? moment(value, "x").format("DD MMM YYYY, hh:mm:ss a") : '';
    },

    ellipsis: (value, MAX_LEN = 15) => {
        if (value) return value.length > MAX_LEN ? (value.slice(0, MAX_LEN) + '...') : value;
        return null;
    },

    password: (value) => {
        if (value)
            return value.split("").map(() => "*").join("");
    },

    commas: (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}