import axios from 'axios';
import router from '../router'
import { ToastFix } from './toastr';

export const http = axios.create({
    // baseURL: ' http://localhost:3000/dev/v1'
    //  baseURL: 'https://v5mtakvvs2.execute-api.ap-southeast-1.amazonaws.com/dev/v1'
})

export const setupHttp = () => {
    http.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 401) {
            ToastFix("Unauthorized", "danger", 2);
            router.push({ name: "Login" });
        } if (error.response.status === 422 || error.response.status === 400) {
            let errorData = error.response.data;
            if (Array.isArray(errorData)) errorData.forEach(error => ToastFix(error, "danger"));
            else ToastFix(errorData.message || errorData, "danger");
        }
        return Promise.reject(error);
    });
}
