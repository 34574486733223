<template>
  <div class="buyer">
    <div class="container-fluid buyer-container">
      <div class="proship-logo-wrapper text-center">
        <img src="@/assets/proship-logo-sm.png" alt class="proship-logo" />
      </div>
      <div class="buyer-content d-flex justify-content-center">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.buyer {
  background-color: $dashboard-background;
  position: relative;
}
.buyer-container {
  min-height: 100vh;
  max-width: 100%;
}

.proship-logo {
  width: 141px;
  margin-top: 30px;
  margin-bottom: 30px;

  @include for-laptops-only {
    margin: 10px 0;
  }
}
</style>
